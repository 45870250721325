import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import {
  fetchStory,
} from '@actions/stories';

const styles = theme => ({
  root: {
    marginTop: 80,
    flexGrow: 1,
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '60%',
    }
  },
  formatted: {
    wordWrap: 'break-word',
    overflowX: 'auto',
    whiteSpace: 'pre-wrap',
    background: 'rgba(0,0,0,0)'
  }
})

class _StoryPage extends Component {
  componentDidMount() {
    const {
      match: {
        params: {
          id
        }
      }
    } = this.props;

    this.props.fetchStory(id)
  }

  render() {
    const {
      classes,
      story,
    } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h2" component="h2">{story.title}</Typography>
        <br/>
        <pre className={classes.formatted}><Typography>{story.body}</Typography></pre>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  story: state.stories.story,
})

const mapDispatchToProps = dispatch => ({
  fetchStory: (id) => dispatch(fetchStory(id))
})

export const StoryPage = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(_StoryPage));
