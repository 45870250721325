export const CREATE_SESSION = 'CREATE_SESSION';
export const DESTROY_SESSION = 'DESTROY_SESSION';

export const createSession = (user) => dispatch => {
  dispatch({
    type: CREATE_SESSION,
    user
  });
}

export const destroySession = () => dispatch => {
  dispatch({
    type: DESTROY_SESSION
  })
}
