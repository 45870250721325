export const OPEN_SIDE_DRAWER = 'OPEN_SIDE_DRAWER';
export const CLOSE_SIDE_DRAWER = 'CLOSE_SIDE_DRAWER';

export const openSideDrawer = () => dispatch => {
  dispatch({
    type: OPEN_SIDE_DRAWER
  })
}

export const closeSideDrawer = () => dispatch => {
  dispatch({
    type: CLOSE_SIDE_DRAWER
  })
}
