export const SET_RANGE = 'SET_RANGE';
export const REQUEST_SET_RESULT = 'REQUEST_SET_RESULT';
export const SET_RESULT = 'SET_RESULT';
export const RESET_RESULTS = 'RESET_RESULTS';
export const SET_DIFFICULTY = 'SET_DIFFICULTY';

export const setRange = (range) => dispatch => {
  dispatch({
    type:SET_RANGE,
    range
  })
}

export const computeResults = () => (dispatch, getState) => {
  dispatch({
    type: REQUEST_SET_RESULT  
  })

  const {
    waitTurn
  } = getState();

  const range = waitTurn.range;

  var result =
    Math.floor(Math.random() * (range[1] - range[0] + 1)) + range[0];

  dispatch({
    type: SET_RESULT,
    result
  })
}

