import {
  SET_MAX,
  REQUEST_SET_RESULT,
  SET_RESULT,
  RESET_RESULTS
} from '../actions/spank-decider';

const DEFAULT_STATE = {
  max: 5,
  result: -1,
  isSettingResult: false
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_MAX:
      return Object.assign({}, state, {
        max: action.max
      })
    case SET_RESULT:
      return Object.assign({}, state, {
        isSettingResult: false,
        result: action.result
      })
    case REQUEST_SET_RESULT:
      return Object.assign({}, state, {
        isSettingResult: true,
        result: action.result
      })
    case RESET_RESULTS:
      return Object.assign({}, state, {
        isSettingResult: false,
        result: -1,
      })
    default:
      return state
  }
}
