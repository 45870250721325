import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';

import theme from './theme';
import Layout from '@/hoc/layout';
import HomePage from '@pages/home-page';
import LoginPage from '@pages/login-page';
import { StoriesPage } from '@pages/stories-page';
import { StoryPage } from '@pages/story-page';
import { CreateStoryPage } from '@pages/create-story-page';
import { NovelPage } from '@pages/novel-page';
import WaitYourTurnPage from '@pages/games/wait-your-turn-page';
import SpDeciderPage from '@pages/games/sp-decider-page';
import history from '@/modules/history';
import ReactGA from 'react-ga';

import {
  createSession,
} from './actions/session'

class App extends Component {
  componentDidMount() {
    history.listen(this.handleRouteChange);
    this.checkLoginStatus();
  }

  handleRouteChange() {
    ReactGA.pageview(location.pathname)
  }

  checkLoginStatus() {
    axios.get("/api/logged_in", { withCredentials: true })
      .then(response => {
        const {
          data: {
            user
          }
        } = response;

        if (user) {
          this.props.onLoginCheck(user);
        }
      })
      .catch(error => {
        console.log('The error is: ', error)
      });
  }

  render() {
    const {
      classes
    } = this.props;

    let routes = (
      <Switch>
        <Route exact path='/' component={HomePage} />
        <Route exact path='/stories' component={StoriesPage} />
        <Route exact path='/stories/:id' component={StoryPage} />
        <Route exact path='/series/:id' component={NovelPage} />
        <Route exact path='/games/spank-decider' component={SpDeciderPage} />
        <Route exact path='/games/wait-your-turn' component={WaitYourTurnPage} />
      </Switch>
    );

    return(
      <div>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Layout>
            {routes}
          </Layout>
        </MuiThemeProvider>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLoginCheck: (user) => {
      dispatch(createSession(user));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
