import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {
  createStory,
} from '@actions/stories';

const styles = theme => ({
  root: {
    marginTop: 80,
    flexGrow: 1,
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  textField: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
  }
})

class _CreateStoryPage extends Component {
  componentDidMount() {
  }

  handleCreateStory() {
    console.log("The story is")
    var data = {
      title: this.state.title,
      body: this.state.body
    }
    this.props.createStory(data)
  }

  handleTextChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: e.target.value })
  }

  render() {
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.root}>
        <TextField className={classes.textField} id="standard-basic" label="Title" name="title" variant="outlined" onChange={(e) => this.handleTextChange(e)}/>
        <br/>
        <br/>
        <TextField className={classes.textField} id="filled-multiline-static" label="Body" multiline rows="10" name="body" variant="outlined" onChange={(e) => this.handleTextChange(e)} />

        <br/>
        <Button variant="contained" color="primary" onClick={() => this.handleCreateStory()}>Create Story</Button>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state,
})

const mapDispatchToProps = dispatch => ({
  createStory: (data) => dispatch(createStory(data))
})

export const CreateStoryPage = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(_CreateStoryPage));
