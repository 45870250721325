import axios from 'axios';

export const REQUEST_NOVELS = 'REQUEST_NOVELS';
export const RECEIVE_NOVELS_SUCCESS = 'RECEIVE_NOVELS_SUCCESS';
export const RECEIVE_NOVELS_ERROR = 'RECEIVE_NOVELS_ERROR';

export const REQUEST_NOVEL = 'REQUEST_NOVEL';
export const RECEIVE_NOVEL_SUCCESS = 'RECEIVE_NOVEL_SUCCESS';
export const RECEIVE_NOVEL_ERROR = 'RECEIVE_NOVEL_ERROR';

import { get } from 'lodash';

export const fetchNovels = () => dispatch => {
  axios.get('/api/novels')
    .then((response) => {
      dispatch({
        type: RECEIVE_NOVELS_SUCCESS,
        novels: response.data
      });
    })
    .catch((error) => {
      console.log('The error is: ', error);
    });
}

export const fetchNovel = (id) => dispatch => {
  axios.get(`/api/novels/${id}`)
    .then((response) => {
      dispatch({
        type: RECEIVE_NOVEL_SUCCESS,
        novel: response.data 
      })
    })
    .catch((error) => {
      console.log('The error is: ', error);
    });
}
