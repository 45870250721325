import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Paper,
  TextField,
  Button,
  Grid,
  Typography
} from '@material-ui/core';


class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    }
  };

  handleTextChange = (event) => {
    const {
      target: {
        name,
        value,
      }
    } = event;

    this.setState({[name]: value})
  }

  render() {
    
    return(
      <div>
        <Grid justify="center" container spacing={3}>
          <Grid item xs={3}>
            <Paper>
              <Typography gutterBottom variant="h4">Login</Typography>
              <form>
                <TextField
                  variant="outlined"
                  label="username"
                  margin="dense"
                  name="username"
                  onChange={this.handleTextChange}
                />
                <TextField
                  variant="outlined"
                  label="Password"
                  type="password"
                  margin="dense"
                  name="password"
                  onChange={this.handleTextChange}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Login
                </Button>

              </form>
            </Paper>
          </Grid>
        </Grid>

      </div>
    )
  }
}

export default LoginPage;
