import { combineReducers } from 'redux';
import main from './main';
import stories from './stories';
import session from './session';
import waitTurn from './wait-turn';
import spankDecider from './spank-decider';
import novels from './novels';

export default combineReducers({
  main,
  stories,
  session,
  waitTurn,
  spankDecider,
  novels,
});
