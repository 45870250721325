import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import history from '@/modules/history';

import App from './app';
import configureStore from './store';

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_CODE);
ReactGA.pageview(location.pathname)

const app = (
  <Router history={history}>
    <Provider store={configureStore()}>
    <App />
  </Provider>
  </Router>
)

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    app, document.getElementById('games'),
  )
});
