import axios from 'axios';

export const REQUEST_STORIES = 'REQUEST_STORIES';
export const RECEIVE_STORIES_SUCCESS = 'REQUEST_STORIES_SUCCESS';
export const RECEIVE_STORIES_ERROR = 'RECEIVE_STORIES_ERROR';
export const REQUEST_STORY = 'REQUEST_STORY';
export const RECEIVE_STORY_SUCCESS = 'RECEIVE_STORY_SUCCES';
export const RECEIVE_STORY_ERROR = 'RECEIVE_STORY_ERROR';


export const fetchStories = () => dispatch => {
  axios.get('/api/stories')
    .then((response) => {
      dispatch({
        type: RECEIVE_STORIES_SUCCESS,
        stories: response.data
      });
    })
    .catch((error) => {
      console.log('The error is: ', error);
    });
}

export const fetchStory = (id) => dispatch => {
  axios.get(`/api/stories/${id}`)
    .then((response) => {
      dispatch({
        type: RECEIVE_STORY_SUCCESS,
        story: response.data,
      })
    })
    .catch((error) => {
      console.log('The error is: ', error);
    });
}

export const createStory = (data) => {
  console.log('The data is: ', data);
  return (dispatch, getState) => {
    console.log('The getState is: ', getState())
    const user = getState().session.user


    return axios.post('/api/stories', data)
      .then((response) => {
        console.log('The response is: ', response)
      })
      .catch((error) => {
        console.log('The error is: ', error)
      })
    console.log('The story data is: ', data);
  }
}
