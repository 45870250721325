import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#5f0937',
    },
    secondary: {
      main: '#330e62',
    },
    background: {
      default: '#121212'
    },
  }
});
