import {
  OPEN_SIDE_DRAWER,
  CLOSE_SIDE_DRAWER,
} from '../actions/main'

const DEFAULT_STATE = {
  drawerOpen: false
}

export default (state = DEFAULT_STATE,  action) => {
  switch (action.type) {
    case OPEN_SIDE_DRAWER:
      return Object.assign({}, state, {
        drawerOpen: true
      });
    case CLOSE_SIDE_DRAWER:
      return Object.assign({}, state, {
        drawerOpen: false
      });
    default:
      return state
  }
}
