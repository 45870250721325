import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import {
  fetchStories,
} from '../actions/stories';

const styles = theme => ({
  root: {
    marginTop: 80,
    flexGrow: 1,
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }
})

class _StoriesPage extends Component {
  componentDidMount() {
    this.props.fetchStories();
  }

  render() {
    const {
      classes,
      stories,
    } = this.props;


    let storiesGrid = <Typography>Loading...</Typography>

    if (stories.length > 0) {
      storiesGrid = stories.map((story) => {
        return (<Grid item xs={12} md={6} lg={3} key={story.id}>
          <Link to={`stories/${story.id}`}>
            <Paper className={classes.paper}>
              <Typography variant="h5" component="h5">
                {story.title}
              </Typography>
            </Paper>
          </Link>
        </Grid>
        )
      });
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          {
            storiesGrid
          }
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  stories: state.stories.stories,
})

const mapDispatchToProps = dispatch => ({
  fetchStories: () => dispatch(fetchStories())
})

export const StoriesPage = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(_StoriesPage));
