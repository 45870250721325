import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';

import {
  closeSideDrawer,
} from '../../actions/main';

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  linkStyle: {
    textDecoration: 'none',
    color: '#fff',
  },
});


class _SideBar extends Component {
  render() {
    const {
      classes,
      main
    } = this.props;

    return(
      <div className={classes.list}>
        <Drawer
          open={main.drawerOpen}
          onClose={() => this.props.closeSideDrawer()}
        >
          <Divider/>
          <List className={classes.list}>
            <Link to="/games" className={classes.linkStyle}>
              <ListItem button key="games">
                <ListItemIcon><VideogameAssetIcon /></ListItemIcon>
                <ListItemText primary="Games" />
              </ListItem>
            </Link>
            <Link to="/stories" className={classes.linkStyle}>
              <ListItem button key="stories">
                <ListItemIcon><MenuBookIcon /></ListItemIcon>
                <ListItemText primary="Stories" />
              </ListItem>
            </Link>
          </List>
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  closeSideDrawer: () => dispatch(closeSideDrawer())
})

export const SideBar = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(_SideBar));
