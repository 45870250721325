import {
  RECEIVE_NOVELS_SUCCESS,
  RECEIVE_NOVEL_SUCCESS,
} from '../actions/novels';

const DEFAULT_STATE = {
  novels: [],
  novel: {},
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case RECEIVE_NOVELS_SUCCESS:
      return Object.assign({}, state, {
        novels: action.novels,
      })
    case RECEIVE_NOVEL_SUCCESS:
      console.log('The action is: ', action.novel)
      return Object.assign({}, state, {
        novel: action.novel,
      })
    default:
      return state
  }
}
