import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import easyImage from '@/shared/games/wait-turn/easy.jpg';
import mediumImage from '@/shared/games/wait-turn/medium.jpg';
import hardImage from '@/shared/games/wait-turn/hard.jpg';
import onTop from '@/shared/games/wait-turn/on-top.jpg';
import squeeze from '@/shared/games/wait-turn/squeeze.jpg';
import panties from '@/shared/games/wait-turn/panties.jpg';
import shower from '@/shared/games/wait-turn/shower.jpg';
import condom from '@/shared/games/wait-turn/condom.jpg';

import {
  setRange,
  computeResults,
  RESET_RESULTS,
  SET_DIFFICULTY,
} from '../../actions/wait-turn';


const styles = theme => ({
  root: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 80,
  },
  disclaimer: {
    color: '#aaa',
    fontSize: 13,
    width: '100%',
  },
  question: {
    width: '100%',
    marginTop: 24,
  },
  dialog: {
    padding: theme.spacing(2),
  },
  difficultyButton: {
    width: '100%',
  },
  difficultyImage: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '60%',
    }
  },
  description: {
    width: '100%',
  },
  popupImage: {
    maxWidth: '80%',
    margin: 'auto',
    maxHeight: 200,
    margin: 'auto',
  }
})

const difficultyLevels = {
  'easy': [1, 3],
  'medium': [3, 6],
  'hard': [5, 12],
  'custom': [1, 3]
}

const images = [
  easyImage,
  mediumImage,
  hardImage
]

class WaitYourTurnPage extends Component {
  render() {
    const {
      classes,
      waitTurn: {
        range,
        result,
        isSettingResult,
        difficulty,
      }
    } = this.props

    const showResult = result > 0;

    const difficultyButtons = Object.keys(difficultyLevels).map((e, index) => {
      const variant = index == difficulty ? "contained" : "outlined";
      const color = index == difficulty ? "primary" : "default";
      return <Grid item xs={3} key={index}> 
        <Button variant={variant} color={color} className={classes.difficultyButton} onClick={() => this.props.onDifficulty(index, e)}>
          { e }
          </Button>
      </Grid>
    })

    const difficultyImage = images[difficulty];
    const popupResults = [
      {
        image: squeeze,
        text: "How does that make you feel? Some other guy is going to get to squeeze your wife's ass. Something you're not allowed to do."
      },
      {
        image: onTop,
        text: "Imagine that... it's not just the bull that wants it but your wife as well. She can't wait to get started."
      },
      {
        image: panties,
        text: "She's going to take the panties that you bought her off for some other guy. Picture it. She probably got those panties all wet in anticipation."
      },
      {
        image: shower,
        text: "Does it make you jealous that she's going to be intimate with so many other guys? Do you think she thinks about you when she's getting ready to fuck someone else? I doubt it."
      },
      {
        image: condom,
        text: "How would you feel if after getting back from her date she showed you that the condom she was supposed to use wasn't even unrolled? Would you eat her out? Go ahead. Taste her."
      }
    ]


    var randomNumber =
      Math.floor(Math.random() * (5))


    let popupResult = popupResults[randomNumber]

    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Typography variant="h3" component="h3">
            Wait Your Turn
          </Typography>
          <Typography className={classes.description}>
            Let the game decide how many guys your wife should sleep
            with before you get any attention.
            It's fun leaving stuff up to chance sometimes.
          </Typography>
          <Typography className={classes.disclaimer}>
            Warning: Play this game at your own discretion. This game is meant to
            be of a fantasy type and should not be used as a guide. 
            You are responsible for your own actions.
          </Typography>
            <Grid item xs={12}>
              <Typography>
                Select your game difficulty.
              </Typography>
              <br/>
              <Grid container spacing={1}>
                { difficultyButtons }
              </Grid>
              {difficulty != Object.keys(difficultyLevels).length - 1 &&
                <div>
                  <br/>
                  <img src={difficultyImage} className={classes.difficultyImage} />
                </div>
              }
            </Grid>
              <Grid item xs={12}>
                {difficulty == Object.keys(difficultyLevels).length - 1 &&
                  <div>
                    <Typography id="range-slider" gutterBottom>
                      How many guys should your wife sleep with before you get attention?
                    </Typography>
                    <br/>
                    <br/>
                    <Slider
                      defaultValue={range}
                      aria-labelledby="range-slider"
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={1}
                      max={20}
                      valueLabelDisplay="on"
                      onChange={this.props.onSetRange}
                    />
                  </div>
                }
                <Button
                  onClick={this.props.onComputeResults}
                  variant="contained"
                  color="primary"
                >
                Take your Chances
                </Button>
              </Grid>
        </Grid>
        <Dialog open={showResult} onClose={() => this.props.onResetResults()} className={classes.dialog}>
          <DialogTitle>
            How long will you have to wait your turn?
          </DialogTitle>
           
          <DialogContent>
            <DialogContentText>
              {`Your wife is gonna have to sleep with ${result} guys before you get any attention.`}
            </DialogContentText>
            <img src={popupResult.image} className={classes.popupImage} />
            <DialogContentText>
              {popupResult.text}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => this.props.onComputeResults()}>
              Retry
            </Button>
            <Button onClick={() => this.props.onResetResults()}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  waitTurn: state.waitTurn,
})

const mapDispatchToProps = dispatch => ({
  onSetRange: (e, range) => {
    dispatch(setRange(range))
  },
  onComputeResults: () => {
    dispatch(computeResults())
  },
  onResetResults: () => {
    dispatch({ type: RESET_RESULTS })
  },
  onDifficulty: (difficulty, key) => {
    dispatch({ type: SET_DIFFICULTY, difficulty, range: difficultyLevels[key] })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WaitYourTurnPage))
