import {
  SET_RANGE,
  REQUEST_SET_RESULT,
  SET_RESULT,
  RESET_RESULTS,
  SET_DIFFICULTY,
} from '../actions/wait-turn';

const DEFAULT_STATE = {
  range: [3, 6],
  result: -1,
  isSettingResult: false,
  difficulty: 1,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_RANGE:
      return Object.assign({}, state, {
        range: action.range,
      })
    case SET_RESULT:
      return Object.assign({}, state, {
        isSettingResult: false,
        result: action.result
      })
    case REQUEST_SET_RESULT:
      return Object.assign({}, state, {
        isSettingResult: true, 
        result: action.result,
      })
    case RESET_RESULTS:
      return Object.assign({}, state, {
        isSettingResult: false,
        result: -1,
      })
    case SET_DIFFICULTY:
      return Object.assign({}, state, {
        difficulty: action.difficulty,
        range: action.range,
      })
    default:
      return state
  }
}
