import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import {
  openSideDrawer,
} from '../../actions/main'

const styles = theme => ({
  linkStyle: {
    textDecoration: 'none',
    color: '#fff',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
})

class NavBar extends Component {
  render() {
    const {
      classes,
    } = this.props;

    let title = 'Femdom Games';

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      title = 'Games';
    }

    return (
      <div>
        <AppBar position="fixed">
          <Toolbar>
            <Link to="/" className={classes.linkStyle}>
              <Typography variant="h4">
                { title }
              </Typography>
            </Link>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  openSideDrawer: () => dispatch(openSideDrawer())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NavBar));
