import {
  CREATE_SESSION,
  DESTROY_SESSION,
} from '../actions/session'

const DEFAULT_SESSION_STATE = {
  isLoggedIn: false,
  user: null,
}

export default (state = DEFAULT_SESSION_STATE, action) => {
  switch (action.type) {
    case CREATE_SESSION: {
      console.log('The user is: ', action.user)
      return Object.assign({}, state, {
        isLoggedIn: true,
        user: action.user,
      })
    }
    case DESTROY_SESSION: {
      return Object.assign({}, state, {
        isLoggedIn: false,
        user: null,
      })
    }
    default:
      return state
  }
}
