import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import waitYourTurnImage from '../shared/games/wait-your-turn.jpg'
import spankDeciderImage from '../shared/games/spank-decider.jpg'

import {
  fetchStories,
} from '../actions/stories';

import {
  fetchNovels,
} from '../actions/novels';

const styles = theme => ({
  root: {
    marginTop: 80,
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: '#777',
    },
    minHeight: 140,
  },
  novel: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: '#777',
    },
    minHeight: 50,
  },
  link: {
    textDecoration: 'none',
  },
  gameImage: {
    width: '100%',
  },
  description: {
    color: '#aaa',
  },
  gameCard: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6
    }
  }
})


class HomePage extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.fetchStories();
    this.props.fetchNovels();
  }

  render() {
    const {
      classes,
      stories,
      novels,
    } = this.props;

    let novelsGrid = <Typography>Loading Series...</Typography>


    if (novels.length > 0) {
      novelsGrid = novels.map((novel) => {
        return (<Grid item xs={12} md={6} lg={3} key={novel.id}>
          <Link to={`series/${novel.slug}`} className={classes.link}>
            <Paper className={classes.novel}>
              <Typography variant="h5" component="h5">
                {novel.title}
              </Typography>
            </Paper>
          </Link>
        </Grid>)

      })
    }

    let storiesGrid = <Typography>Loading Stories...</Typography>

    if (stories.length > 0) {
      storiesGrid = stories.map((story) => {
        const storySubstring = story.body.substring(0, 144)
        return (<Grid item xs={12} md={6} lg={3} key={story.id}>
          <Link to={`stories/${story.slug}`} className={classes.link}>
            <Paper className={classes.paper}>
              <Typography variant="h5" component="h5">
                {story.title}
              </Typography>
              <Typography>
                {`${storySubstring}...`}
              </Typography>
            </Paper>
          </Link>
        </Grid>
        )
      })
    }

    return(
      <div className={classes.root}>
        <Typography variant="h6" gutterBottom>
          Welcome to FemdomGames!! This is the new site and will be seeing many improvements on a regular basis. The content on this site is 18+ only.
        </Typography>
        <Typography variant="h3" gutterBottom>
          Series
        </Typography>
        <Grid container spacing={2}>
          {
            novelsGrid
          }
        </Grid>
        <br/>
        <Typography variant="h3" gutterBottom>
          Stories
        </Typography>
        <Grid container spacing={2}>
          {
            storiesGrid
          }
        </Grid>
        <br/>
        <br/>
        <Typography variant="h3" gutterBottom>
          Games
        </Typography>
        <Typography>
          We are currently reworking many of the previous offered games and will be adding many more. We are really sorry for the inconvenience.
        </Typography>
        <Typography>
          <Grid container spacing={2}>
            <Grid item sm={12} md={6} lg={3}>
              <Link to='games/wait-your-turn' className={classes.link}>
                <Card className={classes.gameCard}>
                  <img src={waitYourTurnImage} className={classes.gameImage} />
                  <CardContent>
                    <Typography variant="h5" component="h2">Wait Your Turn</Typography>
                    <Typography className={classes.description}>
                      As a cuckold, you have to be prepared to wait for your wife to
                      have her share of experiences before you get a chance to 
                      have yours. This decider will tell you
                      how long you'll have to wait...
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <Link to='games/spank-decider' className={classes.link}>
                <Card className={classes.gameCard}>
                  <img src={spankDeciderImage} className={classes.gameImage} />
                  <CardContent>
                    <Typography variant="h5" component="h2">Spank Decider</Typography>
                    <Typography className={classes.description}>
                      If you're having trouble deciding how many times your
                      slave should be spanked, let the Spank Decider do it
                      for you... be careful what you wish for. It could get really
                      painful.
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </Typography>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  stories: state.stories.stories,
  novels: state.novels.novels,
})

const mapDispatchToProps = dispatch => ({
  fetchStories: () => dispatch(fetchStories()),
  fetchNovels: () => dispatch(fetchNovels()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HomePage));
