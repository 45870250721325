export const SET_MAX = 'SET_MAX';
export const REQUEST_SET_RESULT = 'REQUEST_SET_RESULT';
export const SET_RESULT = 'SET_RESULT';
export const RESET_RESULTS = 'RESET_RESULTS';

export const setMax = (max) => dispatch => {
  dispatch({
    type: SET_MAX,
    max
  })
}

export const computeResults = () => (dispatch, getState) => {
  dispatch({
    type: REQUEST_SET_RESULT
  })

  const {
    spankDecider
  } = getState();

  const max = spankDecider.max;

  var result = Math.floor(Math.random() * (max));

  dispatch({
    type: SET_RESULT,
    result
  })
}
