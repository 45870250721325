import {
  RECEIVE_STORIES_SUCCESS,
  RECEIVE_STORY_SUCCESS,
} from '../actions/stories';

const DEFAULT_STATE = {
  stories: [],
  story: {} 
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case RECEIVE_STORIES_SUCCESS:
      return Object.assign({}, state, {
        stories: action.stories,
      })
    case RECEIVE_STORY_SUCCESS:
      return Object.assign({}, state, {
        story: action.story
      })
    default:
      return state
  }
}
