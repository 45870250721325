import React, { Component } from 'react';
import Aux from './aux';
import NavBar from '@components/nav/nav-bar';
import { SideBar } from '@components/nav/side-bar';

class Layout extends Component {
  render() {
    return (
      <Aux>
        <SideBar />
        <NavBar />
        {this.props.children}
      </Aux>
    )
  }
}

export default Layout;

