import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { get } from 'lodash'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import {
  fetchNovel
} from '../actions/novels';

const styles = theme => ({
  root: {
    marginTop: 80,
    flexGrow: 1,
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '60%',
    }
  },
  story: {
    textDecoration: 'none',
    color: '#fff'
  },
  paper: {
    padding: theme.spacing(2)
  }
})

class _NovelPage extends Component {
  componentDidMount() {
    const {
      match: {
        params: {
          id
        }
      }
    } = this.props;
    this.props.fetchNovel(id)
  }

  render() {
    const {
      classes,
      novel,
    } = this.props;

    let storyList = <Typography>
      No Stories
    </Typography>

    if (novel.stories) {
      storyList = novel.stories.map((story, index) => {
        return <Link to={`/stories/${story.id}`} key={story.id} className={classes.story}>
          <ListItem button>
            <ListItemText primary={`${index + 1}. ${story.title}`} />
          </ListItem>
        </Link>
      });
    }

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h4" component="h4">{novel.title}</Typography>
          <Divider />
          <br/>
          {novel.stories && novel.stories.length > 0 &&
            <List>
              {storyList}
            </List>
          }
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  novel: state.novels.novel,
})

const mapDispatchToProps = dispatch => ({
  fetchNovel: (id) => dispatch(fetchNovel(id))
})

export const NovelPage = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(_NovelPage));
