import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import {
  setMax,
  computeResults,
  RESET_RESULTS,
} from '../../actions/spank-decider';

const styles = theme => ({
  root: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 80,
  },
  disclaimer: {
    color: '#aaa',
    fontSize: 13,
    width: '100%',
  },
  question: {
    width: '100%',
    marginTop: 24,
  },
  dialog: {
    padding: theme.spacing(2),
  }
})

class SpDeciderPage extends Component {
  render() {
    const {
      classes,
      spankDecider: {
        max,
        result,
        isSettingResult,
      }
    } = this.props

    const showResult = result > 0;

    return (
      <div className={classes.root}>
        <Typography variant="h3" component="h2">
          Spank Decider
        </Typography>
        <br/>
        <Grid container spacing={3}>
        <Typography>
            Enter a max number of spanks, close your eyes,
            and click that button. Let the game decide your fate.
            Good luck!
          </Typography>
          <Typography className={classes.disclaimer}>
            Warning: Play this game at your own discretion. This game is meant to
            be of a fantasy type and should not be used as a guide. 
            You are responsible for your own actions.
          </Typography>
            <Grid item xs={12}>
              <Typography id="range-slider" gutterBottom>
                How many times should your slave be spanked?
              </Typography>
              <br/>
              <br/>
              <Slider
                defaultValue={max}
                aria-labelledby="range-slider"
                valueLabelDisplay="auto"
                step={1}
                marks
                min={1}
                max={50}
                valueLabelDisplay="on"
                onChange={this.props.onSetMax}
              />
              <Button
                onClick={this.props.onComputeResults}
                variant="contained"
                color="primary"
              >
              Take your Chances
              </Button>
          </Grid>
        </Grid>
        <Dialog open={showResult} onClose={() => this.props.onResetResults()} className={classes.dialog}>
          <DialogTitle>
            How many will it be?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {result}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.onComputeResults()}>
              Retry
            </Button>
            <Button onClick={() => this.props.onResetResults()}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  spankDecider: state.spankDecider,
})

const mapDispatchToProps = dispatch => ({
  onSetMax: (e, max) => {
    dispatch(setMax(max))
  },
  onComputeResults: () => {
    dispatch(computeResults())
  },
  onResetResults: () => {
    dispatch({ type: RESET_RESULTS })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SpDeciderPage))
